.banners-wrapper {
    .banner-wrapper {
        a {
            background-size: contain !important;
            background-position: center;
        }
    }
    .container{
        otp-calculator{
            margin-left: 16%;
        }
    }
}